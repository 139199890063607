<template>
  <LoadingSpinner v-if="loading" />
  <q-page v-else class="flex flex-center">
    <q-card class="text-center customcard" style="width: 300px">
      <q-card-section>
        <img src="../../../a-general/assets/icon_red.png" style="max-width: 100px; height: 70px; margin: auto" />
        <q-input v-model="username" label="Mitarbeiter ID" type="text" autofocus style="margin-top: 25px">
          <template v-slot:append>
            <q-icon name="person" class="cursor-pointer" />
          </template>
        </q-input>
        <q-input v-model="password" label="Passwort" :type="passwordVisible ? 'text' : 'password'"
          style="margin-top: 25px" v-on:keyup.enter="handleLogin">
          <template v-slot:append>
            <q-icon :name="passwordVisible ? 'visibility_off' : 'visibility'" class="cursor-pointer"
              @click="passwordVisible = !passwordVisible"><q-tooltip>Passwort {{ passwordVisible ?
                "ausblenden" : "anzeigen" }}
              </q-tooltip></q-icon>
          </template>
        </q-input>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn label="Login" @click="handleLogin" color="primary" style="width: 100%" />
      </q-card-actions>
    </q-card>
    <q-dialog v-model="errorDialogVisible" :content-css="{ color: 'red' }">
      <q-card>
        <q-card-section>
          <div class="text-center">
            <h6>Anmeldung fehlgeschlagen</h6>
            <p>
              Bitte überprüfen Sie Ihre Anmeldeinformationen und versuchen Sie
              es erneut
            </p>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Ok" @click="errorDialogVisible = false" color="primary" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import LoadingSpinner from "../../../a-general/components/loading_spinner.vue"
import { ref } from 'vue'

export default {
  name: "LoginPage",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      username: ref(""),
      password: ref(""),
      passwordVisible: false,
      errorDialogVisible: false,
    }
  },
  mounted() {
    document.title = "Login"
  },
  methods: {
    async handleLogin() {
      if (this.username && this.password) {
        this.loading = true;

        const auth = await this.$store.dispatch("auth/login", {
          user_id: this.username,
          password: this.password,
        });

        if (!auth) {
          this.errorDialogVisible = true;
          this.loading = false;
          return;
        }

        this.$router.push('/dashboard');
      } else {
        this.errorDialogVisible = true;
      }
    },
  }
};
</script>

<style>
.customcard {
  background-color: #000000;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>