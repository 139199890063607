const admin = {
  namespaced: true,
  state: [],
  actions: {
    setUser({ commit }, data) {
      commit("setUser", data);
    },
    setSections({ commit }, data) {
      commit("setSections", data);
    },
    setUser({ commit }, data) {
      commit("setUser", data);
    },
    setKeywords({ commit }, data) {
      commit("setKeywords", data);
    },
    setNews({ commit }, data) {
      commit("setNews", data);
    },
    setNewsArchive({ commit }, data) {
      commit("setNewsArchive", data);
    },
    setSurvey({ commit }, data) {
      commit("setSurvey", data);
    },
    setSurveyArchive({ commit }, data) {
      commit("setSurveyArchive", data);
    },
    setDrivingLicences({ commit }, data) {
      commit("setDrivingLicences", data);
    },
    setChats({ commit }, data) {
      commit("setChats", data);
    },
    setFuelUsers({ commit }, data) {
      commit("setFuelUsers", data);
    },
    setFuelReceipts({ commit }, data) {
      commit("setFuelReceipts", data);
    },
    setFuelAdditional({ commit }, data) {
      commit("setFuelAdditional", data);
    },
    setEducationalDegrees({commit}, data) {
      commit("setEducationalDegrees", data);
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setSections(state, data) {
      state.sections = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setKeywords(state, data) {
      state.keywords = data;
    },
    setNews(state, data) {
      state.news = data;
    },
    setNewsArchive(state, data) {
      state.newsArchive = data;
    },
    setSurvey(state, data) {
      state.survey = data;
    },
    setSurveyArchive(state, data) {
      state.surveyArchive = data;
    },
    setDrivingLicences(state, data) {
      state.drivingLicences = data;
    },
    setChats(state, data) {
      state.chats = data;
    },
    setFuelUsers(state, data) {
      state.fuelUsers = data;
    },
    setFuelReceipts(state, data) {
      state.fuelReceipts = data;
    },
    setFuelAdditional(state, data) {
      state.fuelAdditional = data;
    },
    setEducationalDegrees(state, data) {
      state.educationalDegrees = data;
    },
  },
};

export default admin;
