// Dieser File wird bei git ausgeblendet, damit man nicht immer bei den commits die constants.js Files deaktivieren muss, aufgrund des DEBUG-Modus

// Folgender Inhalt muss dann in der DEBUG.js Datei gesetzt werden.
// In den constants.js - Dateien ist der Inhalt bereits angepasst.
const GENERAL = false;
const ADMIN = true;
const CHECKLIST = true;
const LOGISTIC = true;

export {
  GENERAL,
  ADMIN,
  CHECKLIST as CHECKLIST_DEBUG /* In dem File checklist/src/constants.js gibt es bereits eine Variable mit dem Namen CHECKLIST - deswegen hier der spezielle Name. */,
  LOGISTIC as LOGISTIC_DEBUG,
};
// Kommen neue Projekte dazu, müssen diese natürlich ergänzt werden.
